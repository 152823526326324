import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from './store/login/login.state';
import { Injectable } from '@angular/core';
import { DoLogoutAction } from './store/login/login.action';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthStateProvider {
    private readonly isAuthenticated$: Observable<boolean>;

    constructor(
        private store: Store<AppState>,
        private http: HttpClient
    ) {
        this.isAuthenticated$ = this.store.select(s => s.login.loggedIn);
    }

    public isAuthenticated(): Observable<boolean> {
        return this.isAuthenticated$;
    }

    private privateLogout(): Observable<void> {
        return this.http.post(environment.authLogoutEndpoint, {}).pipe(map(() => this.store.dispatch(new DoLogoutAction())));
    }

    public logOut(): void {
        this.privateLogout().subscribe();
    }
}
